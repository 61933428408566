.container {
  width: 35px;
  height: 35px;
  position: relative;
  flex-shrink: 0;
}
.icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.counter {
  position: absolute;
  right: -3px;
  top: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 13px;
  font-weight: var(--font-bold);
  color: var(--color-black);
  text-decoration: none;;
  background-color: var(--color-white);
  border-radius: 50%;
  box-shadow: var()
}