.list__container {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  padding: 0;
  list-style-type: none;
  gap: var(--spacing-medium);
}
.list__item {
  width: 190px;
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
  cursor: pointer;
}
.list__item a {
  text-decoration: none;
  color: var(--color-black);
  text-align: center;
}
.person__photo {
  width: 100%;
  height: 260px;
  object-fit: cover;
  object-position: top center;
  border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
}