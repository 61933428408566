:root {
  --color-white: #fff;
  --color-black: #000;
  --color-dark-gray: #121212;
  --color-yellow: #ffff00;
  --color-blue: #6a83fb;
  --color-violet: #ae72c2;
  --color-red: #f75d83;
  --color-opaque: #020308;

  --color-gray: #9c9c9c;
  --color-light-gray: #dfe2df;

  --spacing-verysmall: 5px;
  --spacing-small: 10px;
  --spacing-medium: 20px;
  --spacing-large: 30px;

  --list-item-margin: 7px;

  --border-radius-small: 3px;
  --border-radius-medium: 5px;

  --font-size-header: 26px;
  --font-size-subheader: 18px;
  --font-size-main: 16px;

  --width-max: 1200px;

  --font-bold: 600;

  --text-shadow-yellow: 
  0 0 2px var(--color-white),
  0 0 40px var(--color-yellow);

  --text-shadow-blue:
  0 0 2px var(--color-white),
  0 0 40px var(--color-blue);

  --transition: 0.4s;

  /* themes */
  --theme-loading-color: #000;

  --theme-default-header: var(--theme-neutral-header);

  --theme-light-header: #dfe2df;
  --theme-dark-header: #ca6c6f;
  --theme-neutral-header: #cccc46;

  --theme-default-bgimage: var(--theme-loading-color);

  --theme-light-bgimage: linear-gradient(to right bottom, #051937, #162454, #312d70, #533389, #7a359f);
  --theme-dark-bgimage: linear-gradient(to right bottom, #000001, #170e1b, #2f1326, #4a1425, #601a1a);
  --theme-neutral-bgimage: url('@static/bgImage.jpeg');


  --theme-default-menu-background: var(--theme-neutral-menu-background);

  --theme-light-menu-background: #BDC8BD;
  --theme-dark-menu-background: #A74043;
  --theme-neutral-menu-background: #ADAD27;





}

body {
  background-color: var(--color-dark-gray); 
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  font-size: var(--font-size-main);
  background-image: var(--theme-default-bgimage);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
  background-attachment: fixed;
}
img {
  display: block;
}
*, *::after, *::before {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header__text {
  line-height: 1.7;
  font-size: var(--font-size-header);
  text-shadow: 0 0 2px var(--color-black);
  color: var(--theme-default-header);
  transition: color  var(--transition)
}
