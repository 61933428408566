.wrapper {
margin-top: 45px;

}
.person__name {
  padding: var(--spacing-small);
  background-color: var(--color-violet);
  font-size: var(--font-size-subheader);
  color: var(--color-white);
  font-weight: var(--font-bold);
  border-radius: 
  var(--border-radius-small)
  var(--border-radius-small)
  var(--border-radius-small)
  0;
}

.container {
  border-left: 5px solid var(--color-violet);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-medium);
  padding: var(--spacing-medium) 0 0 var(--spacing-medium);
}