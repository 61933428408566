.wrapper {
  width: 230px;
  flex-grow: 1;
  padding: var(--spacing-medium);
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: var(--border-radius-medium);
  color: var(--color-white);
}
.list__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;

}
.list__item {
  width: 200px;
  padding: var(--spacing-verysmall);
  margin-bottom: var(--list-item-margin);
}
.item__title {
  text-decoration: underline;
  text-shadow: var(--text-shadow-blue);
}
@media (max-width: 1060px) {
  
}