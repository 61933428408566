.list__container {
  margin: var(--spacing-medium) 0;
  padding: 0;
  list-style-type: none;
  column-count: 3;
}
.list__item a {
  display: inline-flex;
  color: var(--color-black);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}
.item__image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  object-position: center center;
  border-radius: var(--border-radius-small);
}
.item__label {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 var(--spacing-small);
  color: var(--color-white);
  text-shadow: var(--text-shadow-blue);
}
.search__comment {
  color: var(--color-white)
}
@media screen and (max-width: 850px) {
  .list__container {
    column-count: 2;
  }
}
@media screen and (max-width: 540px) {
  .list__container {
    column-count: 1;
  }
}