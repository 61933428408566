.container {
  --border-radius: 10px;
  --color-light: #2a50e8;
  --color-dark: #ab1928;
  --color-neutral: #bdbd13;
  --color-shadow-light: rgba(42, 80, 232, 0.4);
  --color-shadow-dark: rgba(171, 25, 40, 0.4);
  --color-shadow-neutral: rgb(189, 189, 19, 0.4);

  display: flex;
  margin-top: 40px;
  gap: var(--spacing-medium);
  justify-content: center;
  align-items: center;
}
.item {
  position: relative;
  width: 250px;
  height: 450px;
  border-radius: var(--border-radius);
  transition: var(--transition);
  cursor: pointer;
}
.item__header {
  display: block;
  position: absolute;
  bottom: 20px;
  width: 100%;
  font-size: var(--font-size-subheader);
  text-align: center;
}
.item__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: var(--border-radius);
}
@media (max-width:600px) {
  .container {
    flex-direction: column;
  }
  .item {
    display: flex;
    width: 100%;
    background-color: var(--color-opaque);
    height: 200px;
    justify-content: center;
    align-items: center;
  }
  .item__header {
    position: static;
    padding-left: var(--spacing-verysmall);
  }
  .item__img {
    margin-left: auto;
    width: 100px;
  }
}
.item__light {
  text-shadow: 0 0 5px var(--color-light);
  color: var(--color-light);
}
.item__light:hover {
  box-shadow: 0 0 7px 5px var(--color-shadow-light);
}

.item__dark {
  text-shadow: 0 0 5px var(--color-dark);
  color: var(--color-dark);
}
.item__dark:hover {
  box-shadow: 0 0 7px 5px var(--color-shadow-dark);
}

.item__neutral {
  text-shadow: 0 0 5px var(--color-neutral);
  color: var(--color-neutral);
}
.item__neutral:hover {
  box-shadow: 0 0 7px 5px var(--color-shadow-neutral);
}