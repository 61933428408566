.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-large);
}
.list__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.list__container a {
  display: block;
  margin: 0 7px;
  padding: 7px 10px;
  min-width: 70px;
  color: var(--color-white);
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
  font-weight: var(--font-bold);
  text-shadow: var(--text-shadow-yellow);
  transition: var(--transition);
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: var(--border-radius-small);
}
.list__container :global(a.active) {
  border: 2px solid var(--color-violet);
  color: var(--color-violet);
  text-shadow: none;
 }
 .logo {
  width: 60px;
  height: 60px;
  margin-right: var(--spacing-large);
  object-fit: cover;
  object-position: center center;
}
.icons__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-left: auto;
}
.icons__container {
  gap: 10px;
}
.burger_button {
  border-radius: var(--border-radius-small);
  border: none;
  padding: 4px;
  background-color: var(--color-red);
}
.burger_button_image {
  width: 1.7rem;
  height: 1.7rem;
}
.big_menu__wrapper {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 15;
}
.big_menu__close {
  flex: 1 1 0;
}
.big_menu__container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
  width: 300px;
  max-width: 70%;
  height: 100%;
  padding: var(--spacing-large);
  background-color: var(--theme-default-menu-background);
}
.big_menu {
  flex-direction: column;
  gap: 1rem;
}
.big_menu__container a {
  font-size: 20px;
  line-height: 1.2;
}
.menu__close_btn {
  justify-self: start;
}
.big_menu__icons__container {
 display: flex;
 justify-content: end;
 align-items: center;
 gap: var(--spacing-small);
 height: 60px;
}
.menu__close_btn {
  margin-right: auto;
  background-color: transparent;
  border: none;
}
.menu__close_icon {
  width: 2rem;
  height: 2rem;
}