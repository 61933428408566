.wrapper__input {
  --border-radius: 3px;
  --border: 3px;
  --clear-width: 35px;
  width: 300px;
  position: relative;
  display: inline-flex;
}

.input {
  width: 100%;
  padding: 10px 15px;
  border: var(--border) solid var(--color-lightgray);
  border-radius: var(--border-radius);
  font-size: var(--font-size-medium);
  font-family: var(--font-family);
  outline: none;
  padding-right: calc(var(--clear-width)+ 15px);
}
input:focus {
  border: var(--border) solid var(--color-blue);
}
.clear {
  position: absolute;
  top: var(--border);
  right: var(--border);
  width: var(--clear-width);
  height: calc(100% - var(--border)*2);
  padding: 10px;
  object-fit: contain;
  object-position: center center;
  transition: 0.5s;
  opacity: 0.4;
  cursor: pointer;

}
.clear__disabled {
  opacity: 0.2;
}